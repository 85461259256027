<template>
    <div class="loading-backdrop-con animated fadeIn fast" :style="`color: #FE4365;`" :class="{'fadeOut':!data.show, 'in-holiday-theme': isHolidayTheme}" v-if="!data.hide">
        <div class="loading-backdrop">
            <img :src="loader" alt="Loading..">
            <label>{{ data.text || message }}</label>
        </div>
    </div>
</template>

<script>
    import config from '../../../public/static/theme/conf.json'
    import {mapGetters} from 'vuex'
    export default {
        name: 'ar-backdrop',
        props: {
            data: {
                type: Object,
                default: () => ({})
            }
        },
        data() {
            return {
                messages: [
                    "Now Fetching resources..",
                    "Gathering the information you need..",
                    // "Did you know gwapo is what the devs are?",
                    "Quite a bit of information..",
                    "Getting all the data you want..",
                    "Please wait as we are loading our resources.."
                ],
                message: "Now Fetching resources..",
                loaderImage: `/static/theme/loaders/loader4.svg`,
            }
        },
        computed: {
            ...mapGetters(['isHolidayTheme']),
            loader() {
                return this.isHolidayTheme && config.theme.loaders.show ? this.loaderImage : `/static/img/Long-Loader.gif`
            }
        },
        mounted() {
            setInterval(() => {
                this.message = this.messages[this.getIndex(this.messages.length - 1)]
            }, 4000);

            if (this.isHolidayTheme && config.theme.loaders.show) {
                setInterval(() => {
                    this.loaderImage = `/static/theme/loaders/loader${this.getIndex(config.theme.loaders.count)}.svg`;
                }, 10000)
            }
        },
        methods: {
            getIndex(length) {
                return ~~(Math.random() * length) + 1
            }
        }
    }
</script>

<style scoped>
    @font-face {
        font-family: 'ArtemisThemeFont';
        src: url('/static/theme/font/ArtemisThemeFont.ttf');
    }

    .in-holiday-theme.loading-backdrop-con {
        font-family: 'ArtemisThemeFont';
    }

    .in-holiday-theme .loading-backdrop label {
        font-size: 30px;
    }
</style>

<style>
    .loading-backdrop-con {
        position: fixed;
        text-align:center; 
        background: rgba(255,255,255, 0.8);
        overflow: hidden;
        z-index: 9999;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
    }

    .loading-backdrop {
        position: absolute;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        width: 100%;
        left: 50%;
        top: 50%;
    }

    .loading-backdrop img {
        opacity: 0.9;
        width: 298px;
    }

    .loading-backdrop label {
        position: absolute;
        font-weight: 400 !important;
        font-size: 16px;
        bottom: 20px;
        left: 0;
        right:0;
    }

    .in-holiday-theme .loading-backdrop label {
        bottom: -40px;
    }

    .in-holiday-theme .loading-backdrop {
        top: 44%;
    }

    .in-holiday-theme .loading-backdrop img {
        width: 500px;
    }
</style>